import {
  object,
  string,
  nullable,
  boolean,
  array,
  number,
  type Input,
  union,
  any,
  nullish,
  optional,
  nativeEnum,
} from "valibot";
import type { Reviews } from "./Review";
import { favouriteSchema } from "./Favourite";
import type { Tags } from "./Tag";
import { type SupportedPackageType, supportedPackageTypeSchema } from "./Pack";
import { type BloggerReview } from "~/api/restaurant/getBloggerReview";

export const restaurantOpeningHoursSchema = nullish(
  object({
    mon: nullish(string()),
    tue: nullish(string()),
    wed: nullish(string()),
    thu: nullish(string()),
    fri: nullish(string()),
    sat: nullish(string()),
    sun: nullish(string()),
  })
);

export const restaurantPriceSummariesSchema = nullish(
  object({
    lowestPrice: string(),
    highestPrice: string(),
    packageType: supportedPackageTypeSchema,
    pricingType: string(),
  })
);

export const restaurantSchema = object({
  id: string(),
  type: string(),
  attributes: object({
    lowestAycePrice: nullable(string()),
    lowestPpPrice: nullable(string()),
    lowestXpPrice: nullable(string()),
    lowestHsPrice: nullable(string()),
    lowestBfpPrice: nullable(string()),
    lowestSmPrice: nullable(string()),
    lowestHahPrice: nullable(string()),
    pricePerPerson: nullish(
      object({
        amount: nullish(string()),
        currency: nullish(string()),
        symbol: nullish(string()),
        format: nullish(string()),
      })
    ),
    priceAndPricingType: nullish(
      object({
        amount: nullish(number()),
        currency: nullish(string()),
        symbol: nullish(string()),
        format: nullish(string()),
        pricingType: nullish(string()),
      })
    ),
    isDeleted: nullish(boolean()),
    lat: nullish(string()),
    lng: nullish(string()),
    largestTable: nullish(number()),
    branchId: nullish(number()),
    minPartySize: nullish(number()),
    name: nullish(string()),
    names: nullish(object({ th: string(), en: string() })),
    slug: nullish(string()),
    favorited: nullish(boolean()),
    allowBooking: nullish(boolean()),
    availability: nullish(string()),
    reviewsScore: nullish(number()),
    reviewsCount: nullish(number()),
    address: nullish(string()),
    mapLocation: nullish(string()),
    promotedByHh: nullish(boolean()),
    enableBigGroupFeature: nullish(boolean()),
    location: nullish(string()),
    primaryLocation: nullish(
      object({ id: nullish(number()), name: nullish(string()) })
    ),
    cuisine: nullish(string()),
    primaryCuisine: nullish(
      object({ id: nullish(number()), name: nullish(string()) })
    ),
    primaryDiningStyle: nullish(
      object({ id: nullish(number()), name: nullish(string()) })
    ),
    imageCoverUrl: nullish(
      object({
        thumb: nullish(string()),
        large: nullish(string()),
        square: nullish(string()),
      })
    ),
    canonicalLink: nullish(string()),
    lastBookingWasMade: nullish(string()),
    totalCovers: nullish(number()),
    timeSlots: nullish(object({})),
    reservationSystemOnly: nullish(boolean()),
    breadcrumbs: array(
      object({
        order: number(),
        title: string(),
        link: string(),
      })
    ),
    parking: nullish(union([string(), boolean()])),
    corkageCharge: nullish(string()),
    openingHours: nullish(string()),
    foodDetails: nullish(string()),
    daysInAdvance: nullish(number()),
    diningStyle: nullish(string()),
    ambience: nullish(string()),
    expiryDate: nullish(string()),
    smallNote: nullish(string()),
    acceptKids: nullish(boolean()),
    selfPickupMessage: nullish(string()),
    customText: nullish(string()),
    bookingFlow: nullish(string()),
    hasMultiplePricing: nullish(boolean()),
    earnPoint: nullish(boolean()),
    recordGuests: nullish(boolean()),
    myMoobanVrLink: nullish(string()),
    acceptVoucher: nullish(boolean()),
    description: nullish(string()),
    customSeats: nullish(array(any())),
    tags: nullish(array(any())),
    logoUrl: nullish(object({ medium: string(), thumb: string() })),
    platform: nullish(array(string())),
    customSectionTitle: nullish(string()),
    customSectionContent: nullish(string()),
    availablePackageTypes: nullish(array(supportedPackageTypeSchema)),
    reservationDurationInHours: nullish(string()),
    openingHoursShort: nullish(string()),
    weekdayOpeningHours: restaurantOpeningHoursSchema,
    openingHoursV2: nullish(string()),
    hashtags: nullish(
      array(
        object({
          id: number(),
          label: string(),
          linkToGroupLandingPage: string(),
        })
      )
    ),
    videos: nullish(array(string())),
    locations: nullish(array(object({ id: number(), title: string() }))),
    cuisines: nullish(array(object({ id: number(), title: string() }))),
    diningStyles: nullish(array(object({ id: number(), title: string() }))),
    jsonld: nullish(string()),
    link: nullish(string()),
    seo: nullish(
      object({
        title: nullable(string()),
        description: nullable(string()),
        keywords: nullable(string()),
      })
    ),
    linkToGroupLandingPage: nullish(string()),
    gbPrimepayPublicKey: nullish(string()),
    supportOrderNow: nullish(boolean()),
    cookingTime: nullish(number()),
    hasDeliveryPricingTier: nullish(boolean()),
    phone: nullish(string()),
    phoneForDelivery: nullish(string()),
    covid19Rating: nullish(
      object({
        overallCleanliness: string(),
        socialDistancing: string(),
        staffProtection: string(),
      })
    ),
    priceSummaries: array(
      nullish(
        object({
          lowestPrice: string(),
          highestPrice: string(),
          packageType: supportedPackageTypeSchema,
          pricingType: string(),
        })
      )
    ),
    hasTicketGroups: nullish(boolean()),
    tncImageUrl: nullish(string()),
    restaurantBranch: nullish(array(any())),
    isAds: optional(boolean()),
    position: optional(number()),
    googleReviewScore: optional(number()),
    totalGoogleReviews: optional(number()),
    acceptedCreditCards: optional(array(string())),
    minutesInAdvance: number(),
  }),
  relationships: nullish(
    object({
      pictures: object({
        data: nullish(array(object({ id: string(), type: string() }))),
      }),
      restaurantPackages: object({
        data: nullish(array(object({ id: string(), type: string() }))),
      }),
      lastReviews: object({ data: nullish(array(any())) }),
      bloggerReviews: object({ data: nullish(array(any())) }),
    })
  ),
});

export type WeekdayOpeningHours = Input<typeof restaurantOpeningHoursSchema>;

export enum RestaurantType {
  featuredResturant = "featured_restaurants",
  restaurant = "restaurants",
  restaurantTags = "restaurant_tags",
}

export const restaurantTypeSchema = nativeEnum(RestaurantType);
export const featuredRestaurantAttrSchema = object({
  startDate: string(),
  totalLocations: nullish(number()),
  totalReviews: nullish(number()),
  avgReviews: nullish(number()),
  branchId: nullish(union([string(), number()])),
  cuisine: nullish(string()),
  location: nullish(string()),
  rank: nullish(number()),
  description: nullish(string()),
  customText: nullish(string()),
  acceptVoucher: boolean(),
  earnPoint: boolean(),
  name: string(),
  names: object({
    th: nullish(string()),
    en: nullish(string()),
  }),
  totalCovers: nullish(number()),
  restaurantId: nullish(number()),
  restaurantEncryptedId: nullish(string()),
  link: string(),
  cover: object({
    thumb: nullish(string()),
    original: nullish(string()),
    slideThumb: nullish(string()),
    square: nullish(string()),
  }),
  lastBookingWasMade: nullish(string()),
  packageTypes: array(string()),
  longPackageTypes: array(
    object({
      typeShort: nullish(string()),
      type: nullish(string()),
    })
  ),
  price: object({
    amount: number(),
    currency: string(),
    symbol: string(),
    format: string(),
  }),
  priceV2: object({
    amount: number(),
    currency: string(),
    symbol: string(),
    format: string(),
  }),
  pricingType: string(),
  covid19Safety: boolean(),
  isAds: optional(boolean()),
  position: optional(number()),
  diningStyle: nullish(string()),
  googleReviewScore: optional(number()),
  totalGoogleReviews: optional(number()),
  minutesInAdvance: nullish(number()),
});

export const featuredRestaurantSchema = object({
  id: string(),
  type: string(),
  attributes: featuredRestaurantAttrSchema,
});

export type FeaturedRestaurant = Input<typeof featuredRestaurantSchema>;

export type Restaurant = Input<typeof restaurantSchema>;

export interface RestaurantTags {
  id: string;
  type: string;
  attributes: {
    position?: number;
    isAds?: boolean;
    cover: {
      url: string;
    };
    title: string;
    titleEn: string;
    tagType: string | null;
    totalRestaurants: number;
  };
}

type FeaturedRestaurantAttr = FeaturedRestaurant["attributes"];
type RestaurantAttr = Restaurant["attributes"];

export interface FeaturedRestaurantModel extends FeaturedRestaurantAttr {
  [x: string]: any;
  id: string;
  type: "featured_restaurants";
  position?: number;
  isAds?: boolean;
  anyDeliveryPackage: boolean;
  anyDineInPackage: boolean;
  anyXperiencePackage: boolean;
  isNewRestaurant: boolean;
  isFavourited: boolean;
}

export interface RestaurantModel extends RestaurantAttr {
  id: string | number;
  type: "restaurants";
  position?: number;
  isAds?: boolean;
  earnPoint: boolean;
  anyDeliveryPackage: boolean;
  anyDineInPackage: boolean;
  anyXperiencePackage: boolean;
  isNewRestaurant: boolean;
}

export type SrcSet = {
  source: string;
  format?: string;
  breakpoint?: {
    minWidth?: number;
    maxWidth?: number;
  };
  width?: number;
  height?: number;
  pixelDensity?: "1x" | "2x";
  useMutator?: boolean;
};

export type RestaurantPriceSummaries = Input<
  typeof restaurantPriceSummariesSchema
>;

export interface RestaurantCardProps {
  id: string | number | null;
  slug?: string | null;
  branchId: string | number | null;
  link: string;
  image: {
    src: string;
    width?: string;
    height?: string;
    sources?: SrcSet[];
    useMutator?: boolean;
  };
  totalLocations: number;
  primaryLocation: string;
  name: string;
  primaryCuisine: string;
  diningStyle?: string;
  reviewsCount: number;
  reviewsScore: string | number;
  price: string | number;
  totalCovers: string | number;
  isLoading?: boolean;
  cardToRender?: string;
  isAds?: boolean;
  isFeatured?: boolean;
  restaurantNameEn?: string;
  restaurantNameTh?: string;
  index?: number;
  restaurantId?: number;
  hideCuisine?: boolean;
  lineClampName?: number;
  hideLocation?: boolean;
  priceType?: string;
  priceSummaries?: RestaurantPriceSummaries[];
  packType?: SupportedPackageType[];
}

export type RestaurantPage = {
  isLoading: boolean;
  restaurantId: string | number;
  restaurantCookingTime: number | string;
  acceptVoucher: boolean;
  acceptKids: boolean;
  tncImageUrl: string;
  diningStyle: string;
  slug: string;
  expiryDate: string;
  availablePackageTypes: SupportedPackageType[];
  supportOrderNow: boolean;
  gbPrimepayPublicKey: string;
  name: string;
  icon: string;
  branchId: number;
  branchesLink: string;
  openingHoursShort: string;
  reviewCount: number;
  reviewStarsCount: {
    star: string;
    value: number;
    starNumber: number;
  }[];
  reviewScore: string;
  lastOrder: string;
  cuisines: {
    id: number;
    title: string;
  }[];
  diningStyles: {
    id: number;
    title: string;
  }[];
  cuisine: {
    id: number;
    name: string;
  };
  primaryDiningStyle: {
    id: number;
    name: string;
  };
  location: {
    id: number;
    name: string;
  };
  tags: Tags;
  featuredImages: { id: string; caption: string; src: string }[];
  reservationSystemOnly: boolean;
  vrLink: string;
  totalCovers: number;
  weekdayOpeningHours: WeekdayOpeningHours;
  reviews: Reviews;
  reviewsData: {
    rating: number;
    data: Reviews;
    page: number;
    anyNextReviews: boolean;
  }[];
  lat: number;
  lng: number;
  address: string;
  mapLocation: string;
  overallScore: {
    average: number;
    overall: {
      food: number;
      ambience: number;
      service: number;
      value: number;
    };
  };
  description: string;
  parking: string | boolean;
  corkageCharge: string;
  page: number;
  daysInAdvance: number;
  pageSize: number;
  loadingReview: boolean;
  anyNextReviews: boolean;
  bloggerReview: BloggerReview[];
  bloggerReviewPage: number;
  anyNextBloggerReview: boolean;
  selectedStar: number;
  allowBooking: boolean;
  canonicalLink: string;
  branchs: Restaurant[];
  loadingReviewsCount: boolean;
  restaurantNameEn: string;
  restaurantNameTh: string;
  priceAndPricingType: {
    amount: number | null | undefined;
    currency: string | null | undefined;
    symbol: string | null | undefined;
    format: string | null | undefined;
    pricingType: string | null | undefined;
  };
  lowestPrice: string;
  videos: string[];
  breadcrumbs: {
    order: number;
    title: string;
    link: string;
  }[];
  isLoadingLoadMore: boolean;
  customSeats: number[] | undefined;
  largestTable: number;
  minPartySize: number;
  anyDeliveryPackage: boolean;
  anyDineInPackage: boolean;
  googleReviewScore: number;
  totalGoogleReviews: number;
  acceptedCreditCards: string[];
  minutesInAdvance: number;
};
export interface RestaurantCardSliderProps {
  restaurants: RestaurantCardProps[];
  slidePerView: number | string;
  showArrow?: boolean;
  showPagination?: boolean;
  sideImage?: boolean;
  isLoading?: boolean;
  spaceBetween?: number | boolean;
  simple?: boolean;
}

export const favouriteRestaurantSchema = favouriteSchema;

export const restaurantIncludedSchema = nullish(
  array(
    object({
      id: string(),
      type: string(),
      attributes: object({
        priority: number(),
        caption: string(),
        item: object({
          url: string(),
          thumb_2x: nullish(object({ url: string() })),
          thumb: nullish(object({ url: string() })),
          thumbCover_2x: nullish(object({ url: string() })),
          thumbCover: nullish(object({ url: string() })),
          thumbCoverSquare_2x: nullish(object({ url: string() })),
          thumbCoverSquare: nullish(object({ url: string() })),
          bigCover: nullish(object({ url: string() })),
          slideThumb_2x: nullish(object({ url: string() })),
          slideThumb: nullish(object({ url: string() })),
          slideThumbSquare_2x: nullish(object({ url: string() })),
          slideThumbSquare: nullish(object({ url: string() })),
        }),
      }),
    })
  )
);

export type RestaurantIncluded = Input<typeof restaurantIncludedSchema>;
export type FavouriteRestaurant = Input<typeof favouriteRestaurantSchema>;
