import {
  object,
  string,
  nullish,
  array,
  number,
  type Input,
  union,
} from "valibot";

export const attributesFavouriteSchema = object({
  logoUrl: nullish(
    object({
      medium: nullish(string()),
    })
  ),
  lat: nullish(string()),
  lng: nullish(string()),
  slug: nullish(string()),
  name: nullish(string()),
  link: nullish(string()),
  reviewsCount: nullish(number()),
  reviewsScore: nullish(number()),
  branchId: nullish(union([string(), number()])),
  expiryDate: nullish(string()),
  primaryCuisine: nullish(
    object({
      id: nullish(union([string(), number()])),
      name: nullish(string()),
    })
  ),
  primaryLocation: nullish(
    object({
      id: nullish(union([string(), number()])),
      name: nullish(string()),
    })
  ),
  primaryDiningStyle: nullish(
    object({
      id: nullish(union([string(), number()])),
      name: nullish(string()),
    })
  ),
  imageCoverUrl: nullish(
    object({
      thumb: nullish(string()),
      large: nullish(string()),
      square: nullish(string()),
    })
  ),
  lastBookingWasMade: nullish(string()),
  totalCovers: nullish(union([number(), string()])),
  availablePackageTypes: nullish(array(string())),
  pricePerPerson: nullish(
    object({
      amount: nullish(union([number(), string()])),
      currency: nullish(string()),
      symbol: nullish(string()),
      format: nullish(string()),
    })
  ),
  priceAndPricingType: nullish(
    object({
      amount: nullish(number()),
      currency: nullish(string()),
      symbol: nullish(string()),
      format: nullish(string()),
      pricingType: nullish(string()),
    })
  ),
  locations: array(
    nullish(
      object({
        id: nullish(number()),
        title: nullish(string()),
      })
    )
  ),
  description: nullish(string()),
  hashtags: array(
    nullish(
      object({
        id: nullish(number()),
        label: nullish(string()),
      })
    )
  ),
  openingHoursShort: nullish(string()),
});

export const favouriteSchema = array(
  object({
    id: nullish(union([string(), number()])),
    type: nullish(string()),
    attributes: nullish(attributesFavouriteSchema),
  })
);

export type Favourite = Input<typeof favouriteSchema>;
